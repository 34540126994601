<template>
  <div class="bbr-program-plans--list">
    <main-app-bar>
      <template v-slot:title> Program Plans </template>

      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'active.plans' }" depressed replace>
            Active Program Plans
          </v-btn>

          <v-btn :to="{ name: 'drafts.plans' }" depressed replace>
            Draft Program Plans
          </v-btn>
          <v-btn :to="{ name: 'archived.plans' }" depressed replace>
            Archived Program Plans
          </v-btn>
        </div>
        <v-btn
          :to="{ name: 'new.plan' }"
          class="bg-primary-gradient ml-auto px-8"
          color="primary"
          rounded
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon>
          Add New Program Plan
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-3 px-10 mb-6">
      <v-row>
        <v-col cols="12">
          <div class="form-container d-flex align-center">
            <div class="search-form">
              <v-text-field
                label="Search Program Plans"
                style="width:400px"
                v-model="search"
                @input="searchChange"
                hide-details
                clearable
                rounded
                solo
                flat
                data-testid="progra-plan-search-input"
              />
            </div>
            <span v-if="isActiveView" class="ml-3">
              <v-btn
                v-if="!activePlansReordering"
                @click="enableActivePlansReorder"
                depressed
              >
                <v-icon>{{ icons.filter }}</v-icon>
                <span class="ml-3">Reorder</span>
              </v-btn>
              <v-btn
                v-if="activePlansReordering && activePlansReordered"
                v-ripple="{ center: true }"
                color="success"
                @click="saveActivePlansReorder"
                text
              >
                Save Changes
              </v-btn>
              <v-btn
                v-if="activePlansReordering"
                @click="cancelActivePlansReorder"
                color="error"
                text
              >
                Cancel
              </v-btn>
            </span>
          </div>
        </v-col>

        <v-col cols="12">
          <transition name="fade" mode="out-in">
            <router-view :is-loading="loading" @fetch="fetchPlans" />
          </transition>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import mapValues from 'lodash/mapValues'
import { mdiPlus, mdiTune } from '@mdi/js'

export default {
  name: 'PlansLists',

  components: {
    MainAppBar,
  },

  data() {
    return {
      search: null,
      loading: false,
      icons: {
        add: mdiPlus,
        filter: mdiTune,
      },
    }
  },

  computed: {
    ...mapState({
      listFilter: (state) => state.plans.filter,
      activePlansReordering: (state) => state.plans.active.reordering,
      activePlans: (state) => state.plans.active.list,
    }),

    ...mapGetters({
      activePlansReordered: 'plans/activePlansReordered',
    }),

    currentListType() {
      return this.$route.meta.type
    },

    isCompletedOnlySearch() {
      return this.currentListType === 'active'
    },
    isArchivedSearch() {
      return this.currentListType === 'archived'
    },

    isActiveView() {
      return this.$route.name === 'active.plans'
    },
  },

  created() {
    this.search = this.listFilter.search
    this.setPlanFilter()
  },

  methods: {
    ...mapActions({
      getPlans: 'plans/getPlans',
      saveActivePlansReorder: 'plans/saveActivePlansReorder',
      reorderPlans: 'plans/reorderPlans',
    }),

    ...mapMutations({
      setFilter: 'plans/setFilter',
      clearList: 'plans/clearCurrentList',
      enableActivePlansReorder: 'plans/enableActivePlansReorder',
      cancelActivePlansReorder: 'plans/cancelActivePlansReorder',
    }),

    ifHasSearchKey() {
      if (this.search) {
      }
    },

    setPlanFilter() {
      this.setFilter({
        search: this.search,
        type: this.currentListType,
        completed: this.isCompletedOnlySearch,
        archived: this.isArchivedSearch,
      })
    },

    searchChange(key) {
      this.setPlanFilter()
      this.searchPlans()
    },

    searchPlans: debounce(function() {
      this.clearList(this.currentListType)
      this.fetchPlans()
    }, 600),

    async fetchPlans(page = 1) {
      if (this.loading) return

      this.loading = true

      await this.getPlans({
        page: page,
        search: this.search,
        completed: this.listFilter.completed,
        archived: this.listFilter.archived,
        sort: ['order'],
      })

      this.loading = false
    },

    async saveActivePlansReorder() {
      let planIds = mapValues(this.activePlans, 'id')
      await this.reorderPlans(planIds)
      this.cancelActivePlansReorder(false)
    },
  },

  watch: {
    '$route.meta.type'(type) {
      this.search = null

      this.setFilter({
        type: type,
        search: this.search,
        completed: this.isCompletedOnlySearch,
        archived: this.isArchivedSearch,
      })
    },
  },
}
</script>
